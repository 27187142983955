.selectable-list{
  .input-group-text{
      background-color: #ffffff !important;
      border-right: none !important;
      
  }
  
  .input-group{
      input{
          border-left: none !important;
      }
  }
  
  .employee-list{
    margin-top:.5em;
    
  
    .card-body{
      height: 100%;
      padding:.5em 1.5em;
      
    }
    
    .list-group {
      flex-direction: row !important;
      height: 154px;
      overflow-y: auto;
    }
    
    .list-group-item{
      display: inline !important;
      border: 1px solid #fff !important;
      cursor: pointer;
      padding: .3em .3em;
      max-height: 64px;
      .img-container{
        padding:.5em;

        .no-photo-user{
          border: 1px solid #fff;
          width: 35px;
          height: 35px;
        }

      }
  
      .row{
        margin: 0;
      }
  
      p{
        margin-bottom: 0px;
      }
  
      &.active{
        background-color:var(--primary-color) ;
        border-radius: 5px;
      }
  
      img{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        margin-right: .5em;
      }
    }
  }
}
