.task-list{
  label{
    cursor: pointer;
  }
  .custom-card{
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    .card-header{
      color:#4F4F4F !important;
      background-color: #fff;
      border: none;
      padding: .7em;
      cursor: pointer;
      label{
        cursor: pointer;
        margin:0 !important;
        font-weight: 600;
        font-size: 18px;
        white-space: pre-wrap;
      }
    }
    .card-body{
      padding:0 .7em .7em .7em;
      .title-card{
        display: block;
        font-size: 12px;
        font-weight: 600;
        margin:0;
      }
      .data-card{
        // display: block;
        font-size: 16px;
        font-weight: 500;
        margin:0;
        i{
          margin-right: .7em;
        }
      }
      .progress{
        border-radius: 8px;
        background-color: #E0E0E0;
      }
    }
  }
  .mg-top{
    margin-top:.5em !important;
  }
  .float-add{
    border-radius: 50px;
    font-size: 30px;
    font-weight: 600;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
    padding:0 !important;
    height: 50px;
    width: 50px;
    line-height: 50px;
    position: fixed;
    bottom: 30px;
    right: 30px;
  }
}

.task-details{
  height: calc(100vh - 83px);
  overflow-y: hidden;
  box-shadow: 3px 51px 14px rgba(0, 0, 0, 0.25) !important;

  .card{
    height: 100%;
  }

  .wrap{
    margin: 0 !important;
    height: 100%;
  }

  .accept-decline-task{
    .lbl-info{
      font-size: 14px;
      color:#4F4F4F;
    }
    .accept{
      color:#0A784A;
    }
    .decline{
      color:#C81A1A;
    }
  }

  .chat{
    background-color: #E5E5E5;
    padding: 0;
    height: calc(100% - 40px);
    .logs-list{
      padding:.5em;
      height: calc( 100% - 46px );
      overflow-y: auto;
      overflow-x: hidden;
      .lbl-log{
        font-size: .8em;
      }
      .log-registry{
        padding: .5em 0;
        .date{
          font-size: 12px;
          line-height: 16px;
        }
      }
      .no-photo-user{
        display: inline-block;
        margin-left: 5px;
        border-radius: 50px;
      }
      .log-comment{
        width: calc( 100% - 58px );
        display: inline-block;
        background-color: #fff;
        padding:.5em .7em;
        border-radius: 4px;
        .name{
          font-size: 15px;
          line-height: 16px;
          font-weight: bold;
        }
        .date{
          // text-align: right;
          font-size: 12px;
          line-height: 14px;
        }
        p{
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          margin:0;
          margin-top:.5em;
        }
      }
    }
    .tag-user{
      background-color: var(--primary-color);
      color: #fff;
      padding: 0 .2em;
      border-radius: 50px;
    }
    .add-message{
      position: absolute;
      bottom: 0;
      height: 46px;
      width: 100%;
      background-color: #fff;
      textarea{
        resize: none;
      }
      .mention{
        // padding-top: 20px; 
        background-color: #fff !important;
        // color: #0000ff;
        text-decoration: underline;
        position: relative;
        z-index: 1;
        color: #4e7bd6;
        text-shadow: 2px 1px 0px white, 0px 0px 0px white, -1px 1px 0px white,
        -1px -1px 0px white;

        pointer-events: none;
        
      }
      .xd__highlighter{
        padding: .64em .43em;
        // background-color: #fff !important;
      }
      
      .xd{
        input{
          // color: #0000ff;
          height: unset;
          border-radius: 0px 0px 4px 0px;
          padding: .7em .5em;
          border: none !important;
          //background-color: #fff !important; 
          &:focus, &:active{
            outline: none;
            border: none !important;
          }
        }
      }
      .xd__highlighter__substring{
        color:#222 !important;
      }
      .form-control{
        height: unset;
        background: #FFFFFF;
        border: none !important;
        border-radius: 0px 0px 4px 0px;
        padding: .7em .5em;
        margin-bottom:0 !important;
      }
    }
  }

  .card-header{
    background-color: #fff;
  }

  .card-body{
    padding:0 !important;
  }

  .detail{
    padding:1em;
    border-right: 1px solid rgba(0,0,0,0.25);
    height: calc(100% - 40px);
    overflow-y: auto;
    label{
      font-size: 16px;
      font-weight: 600;
      margin-top:1em;
      margin-bottom: 0;
    }
  }

  .subtasks{
    .subtask{
      border-radius: 50px;
      padding: .4em 1em;
      cursor: pointer;
      margin-bottom:.5em;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    }
    .no-subtask{
      background-color: #F4F4F4;
      border-radius: 50px;
      padding: .3em 1em;
      text-align: center;
      margin-top: .5em;
    }
  }
}

