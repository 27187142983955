@import './vars';

.bg-blue{
  background-color: $blue;
}

.bg-pink{
  background-color:  $pink;
}

.bg-red{
  background-color: $red !important;
}

.bg-orange{
  background-color: $orange;
}

.bg-yellow{
  background-color: $yellow;
}
.bg-green{
  background-color: $green;
}

.bg-violet{
  background-color: $violet;
}

.bg-gray{
  background-color: #828282;
}

.card-box-container{
    //border:1px solid #000;
  min-height: 50vh;
  padding-top:.5em;
  .cards-container{
    padding: .5em;
    &.hide{
      display: none;
    }
    .container-header{
      background: #fff;
      // padding:.3em .9em;
      border-radius: 4px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      .title{
        font-weight: 600;
        padding-left: 0;
      }
      label{
        margin: 0;
        padding: .1em .5em;
      }
      .counter{
        color:#fff;
        padding: .3em .8em;
        border-radius: 4px;
        font-weight: 500;
        margin-right: .7em;
      }
    }
    .cardD-container{
      padding: .5em 0;
      min-height: 50vh;
      .card{ 
        margin-top:.5em;
        .Card-header{
          color:#fff;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
      &.over{
        border:5px dotted rgb(150, 190, 150);
        &.block{
          border-color: red;
        }
      }
    }
  }
}

.kanban{
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    border-radius: 4px;
  }
  


  .cards-container{
    width: 25%;
    //padding:1em;
    display: inline-grid;
    
    .cardD-container{
      height: calc(100vh - 242px);
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 2px 2px 1em 2px;
      &:hover{
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background: #ccc;
          border-radius: 4px;
        }
      }
      .card{ 
        width: 100%;
        cursor: pointer;
        .Card-header{
          padding:.2em .5em;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          .clip{
            //display: inline;
            float: right;
          }
        }
        .Card-body{
          padding:.5em .5em;
          white-space: initial;
          cursor: pointer;
          label{
            white-space: initial;
            cursor: pointer;
          }
          .card-elm{
            // width: 50%;
            // display: inline-block;
            //text-align: center;
            cursor: pointer;
            padding-top:.5em;
            p{
                margin: 0;
                font-size: 14px;
            }
            .data-title{
                font-weight: 600;
                font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.list{
  .cards-container{
    width: 100%;
    display: block;
    
    .container-header{
      // .counter{
      //   float: left;
      //   margin-right: 1em;
      // }
    }
    .cardD-container{
      .card{ 
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        .Card-header{
          position: absolute;
          //padding:1.2em .5em;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          width: 10%;
          height: 100%;
          top: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          //float:left;
          .clip{
            //display: inline;
            float: right;
          }
        }
        .Card-body{
          padding:.5em .5em;
          margin-left:10%;
          width: 90%;
          display: inline-block;
          cursor: pointer;
          label{
            width: 40%;
          }
          hr{display: none;}
          .card-elm{
            width: 15%;
            display: inline-block;
            //text-align: center;
            //padding-top:.5em;
            p{
              margin: 0;
              font-size: 14px;
            }
            .data-title{
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media(min-width: 1450px) {
  .kanban{
    .cards-container{
      width:  16.66% ;
    }

  }
}
