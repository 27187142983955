
.no-photo-user{
  border-radius: 20px;
  background-color: var(--primary-color);
  color:#fff;
  display: inline-block;
  margin-right:.5em;
  text-transform: uppercase;
  text-align: center;
  label{
    font-weight: 700;
    margin:0 auto !important;
  }
}

